import axios from "axios";
import { backendURL } from "../configurations/config";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import userPool from "../configurations/cognito";

// API endpoint for user module
const USER_MODULE_API = "api/user/";

// Function to sign in user using AWS Cognito
export const signIn = (
  username,
  password,
  onSuccess,
  onFailure,
  onNewPasswordRequired
) => {
  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (session) => {
      console.log("Authentication successful", session);
      onSuccess(session);
    },
    onFailure: (err) => {
      console.error("Authentication failed", err);
      onFailure(err);
    },
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      onNewPasswordRequired(userAttributes, requiredAttributes);
    },
  });
};

// Function to sign out user
export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

// Function to get the current authenticated user
export const getCurrentUser = () => {
  return userPool.getCurrentUser();
};

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  const user = getCurrentUser();
  return !!user;
};

// Function to get user details from backend API
export const getUserDetails = async (emailId) => {
  try {
    const response = await axios.get(
      `${backendURL}${USER_MODULE_API}get_user/${emailId}`
    );
    return response;
  } catch (error) {
    console.error("Error in getUserDetails:", error);
    throw error;
  }
};

// Function to reset user password from backend API
export const resetUserPassword = async (request) => {
  try {
    const response = await axios.post(
      `${backendURL}aws/reset_password`,
      request
    );
    return response;
  } catch (error) {
    console.error("Error in getUserDetails:", error);
    throw error;
  }
};

// Function to reset user password from backend API
export const generateotp = async (request) => {
  try {
    const response = await axios.post(
      `${backendURL}aws/forgot_password`,
      request
    );
    return response;
  } catch (error) {
    console.error("Error in getUserDetails:", error);
    throw error;
  }
};

// Function to reset user password from backend API
export const update_password = async (request) => {
  try {
    const response = await axios.post(
      `${backendURL}aws/confirm_otp_password`,
      request
    );
    return response;
  } catch (error) {
    console.error("Error in getUserDetails:", error);
    throw error;
  }
};
