import React, { useState, useLayoutEffect, useEffect } from "react";
import chatbot from "../../assets/chatbot.png";
import userAvatar from "../../assets/user.png";
import playIcon from "../../assets/play.png";
import copyIcon from "../../assets/copy.svg";
import sendIcon from "../../assets/sendIcon.svg";
import MicIcon from "../../assets/Mic.svg";
import { ChatModuleAPI } from "../../API/chatAPI";
import micGif from "../../assets/mic.gif";

const ChatWindow = ({
  botDetails,
  welcomeMessage,
  activeSession,
  studioId,
  setActiveSession,
  getChatSession,
}) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showIntroAndFAQ, setShowIntroAndFAQ] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [voiceActive, setVoiceActive] = useState(false);
  const [ttsEnabled, setTtsEnabled] = useState(false);
  const [sttEnabled, setSttEnabled] = useState(false);

  useEffect(() => {
    if (activeSession !== "") {
      getSessionChat(activeSession);
    } else {
      setMessages([]);
      setShowIntroAndFAQ(true);
    }
    if (botDetails !== "") {
      setTtsEnabled(botDetails.is_text_to_speech);
      setSttEnabled(botDetails.is_speech_to_text);
    }
  }, [activeSession]);

  const getSessionChat = async (activeSession) => {
    try {
      const response = await ChatModuleAPI.getChatSessionData(activeSession);
      setMessages(response.data.data);
      setShowIntroAndFAQ(false);
    } catch (error) {
      console.error("Error fetching studio details:", error);
    }
  };

  // ? Submit input text for chat response
  const sendRequest = () => {
    if (inputValue.trim()) {
      handleSendMessage(inputValue, "en");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage(event.target.value, "en");
    }
  };

  const handleSendMessage = (query, languageCode) => {
    console.log(query);
    setInputValue("");
    setShowIntroAndFAQ(false);
    setLoading(true);
    let userRequest = {
      type: "user",
      body: query,
    };
    // ? Append chat to session logsp
    setMessages((prevsessionConversation) => [
      ...prevsessionConversation,
      userRequest,
    ]);

    let request = {
      query: query,
      sessionId: activeSession,
      knowledgeBaseId: botDetails.knowledge_base_id,
      account_id: botDetails.accountId,
      channel_type: 1,
      studio_id: studioId,
      user_id: localStorage.getItem("userId"),
    };

    try {
      ChatModuleAPI.chatAPI(request).then((response) => {
        let userRequest = {
          type: "system",
          body: response.data.data.output,
          sourceDoc: response.data.data.sourceDoc,
        };

        setMessages((prevSessionConversation) => [
          ...prevSessionConversation,
          userRequest,
        ]);
        if (activeSession == "") {
          getChatSession(studioId);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFAQClick = (text) => {
    const userMessage = { text, type: "user" };
    setMessages([...messages, userMessage]);
    setShowIntroAndFAQ(false);
    setLoading(true);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <div className="chat-window">
        <div className="chat-body">
          {showIntroAndFAQ && (
            <div className="chat-intro">
              <img src={chatbot} alt="Chatbot" className="chatbot-image" />
              <h2>Welcome to the Shellkode-AI</h2>
              <p>{welcomeMessage}</p>
            </div>
          )}
          <div>
            {messages.map((message, index) => (
              <div key={index} className={`chat-message ${message.type}`}>
                {message.type === "system" && (
                  <>
                    <img
                      src={chatbot}
                      alt="Bot Avatar"
                      className="chat-avatar"
                    />
                    <div className="chat-text">
                      {message.body}
                      <div className="message-actions">
                        {message.hasOwnProperty("sourceDoc") &&
                        message.sourceDoc !== "" ? (
                          <h6 className="sourceTitle">
                            Source Document: {message.sourceDoc}
                          </h6>
                        ) : null}
                        <div className="bot-msg-settings">
                          {ttsEnabled && <img src={playIcon} alt="Play" />}
                          <button onClick={() => handleCopy(message.text)}>
                            <img src={copyIcon} alt="Copy" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {message.type === "user" && (
                  <>
                    <div className="chat-text">{message.body}</div>
                    <img
                      src={userAvatar}
                      alt="User Avatar"
                      className="chat-avatar"
                    />
                  </>
                )}
              </div>
            ))}
            {loading && (
              <div className="chat-message bot align-center">
                <img src={chatbot} alt="Bot Avatar" className="chat-avatar" />
                <div className="">
                  <div className="dots-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* {showIntroAndFAQ && (
          <div className="faq-container">
            <div className="faq">FAQ(s)</div>
            <div className="faq-items">
              <div
                className="faq-item"
                onClick={() =>
                  handleFAQClick(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  )
                }
              >
                working hours of bank
              </div>
              <div
                className="faq-item"
                onClick={() =>
                  handleFAQClick(
                    "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  )
                }
              >
                how to apply for leave
              </div>
              <div
                className="faq-item"
                onClick={() =>
                  handleFAQClick(
                    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                  )
                }
              >
                what is my leave belance
              </div>
            </div>
          </div>
        )} */}
        <div className="chat-footer">
          <div className="chat-input d-flex">
            <input
              className="chat-input-box"
              type="text"
              placeholder="Ask your question"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {sttEnabled && <img src={MicIcon} alt="Mic" className="icon-mic" />}
            <img
              className="icon-send"
              src={sendIcon}
              alt="sendIcon"
              onClick={sendRequest}
            />

            {/* <img className="micGif" src={micGif}></img> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
