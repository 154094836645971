import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login/login.jsx";
import ChatSession from "./components/ChatSession/ChatSession";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loader } from "./utilities/loader.jsx";
import { isAuthenticated } from "./API/loginCognito.jsx";
import Chatbot from "./components/chatWidget/chatbot.jsx";

function App() {
  return (
    <div className="main-container">
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </div>
  );
}

function Main() {
  useEffect(() => {
    loader("stop");
  }, []);

  function PrivateRoute({ children }) {
    const auth = isAuthenticated();
    const isGuest = localStorage.getItem("emailId") === "guest@shellkode.com";
    return auth || isGuest ? <>{children}</> : <Navigate to="/" />;
  }
  return (
    <>
      <ToastContainer autoClose={800} pauseOnFocusLoss={false} />
      <div id="spinner-loader">
        <div className="spinner"></div>
      </div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <ChatSession />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
