import React, { useState, useLayoutEffect, useEffect } from "react";
import SVGExporter from "../SVGExporter/SVGExporter.jsx";
import starIcon from "../../assets/star-icon.png";
import feedabckSuccLogo from "../../assets/feedback_chat_logo.svg";
import { ChatModuleAPI } from "../../API/chatAPI";


const FeedBack = (props) => {
    const [ratingVal, setRatingValues] = useState(["Bad", "Normal", "Average", "Good", "Excellent"]);
    const [NSPScore, setNSPScore] = useState("");
    const [comments, setCommentsVal] = useState("");
    const [CSATScore, setCSATScore] = useState("");
    const [studioId, setStudioId] = useState("");
    const [commentsMandatory, setCommentMandatory] = useState(false);
    const [isFeedbackPage, setFeedbackPageType] = useState(true);
    const [feedbackResponseType, setFeedbackResponseType] = useState("");
    const [feedbackDesc, setFeedbackDesc] = useState("");
    const [recommendVal, setRecommentVal] = useState([
        { val: 1, color: "#E34B16" },
        { val: 2, color: "#EB6C17" },
        { val: 3, color: "#F59517" },
        { val: 4, color: "#EFB718" },
        { val: 5, color: "#EDCA01" },
        { val: 6, color: "#CACE1F" },
        { val: 7, color: "#A2D748" },
        { val: 8, color: "#76D167" },
        { val: 9, color: "#1AC5A8" },
        { val: 10, color: "#4FCC82" }]);

    useLayoutEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const studioId = urlParams.get("studioId");
        setStudioId(studioId);
    }, []);
    const onClickStarRating = (e, val) => {
        let isSet = true;
        ratingVal.map((res, index) => {
            if (res === val && isSet) {
                var nodes = e.target.parentElement.childNodes;
                for (let i = 0; i < nodes.length; i++) {
                    nodes[i].setAttribute('style', 'fill:#FFBB00')
                }
                isSet = false
            } else if (isSet) {
                setCSATScore(index + 1);
                var nodes = document.getElementById(res).children[0].childNodes;
                for (let i = 0; i < nodes.length; i++) {
                    nodes[i].setAttribute('style', 'fill:#FFBB00')
                }
            }
            else {
                var nodes = document.getElementById(res).children[0].childNodes;
                for (let i = 0; i < nodes.length; i++) {
                    nodes[i].setAttribute('style', 'fill:##C8C8C8')
                }
            }
        })
    };

    const onClickRecommend = (e, data) => {
        recommendVal.map((res) => {
            document.getElementById(res.val).style.backgroundColor = "#ffffff";
            document.getElementById(res.val).style.color = "#000000";
        })
        setNSPScore(data.val);
        if (data.val < 6) {
            setCommentMandatory(true);
        } else {
            setCommentMandatory(false);
        }
        document.getElementById(data.val).style.backgroundColor = data.color;
        document.getElementById(data.val).style.color = "#ffffff";
    }

    const onSubmitFeedback = () => {
        if (!commentsMandatory) {
            let request = {
                comments: comments,
                nsp_score: NSPScore,
                csat_score: CSATScore,
                session_id: "",
                channel_type: 1,
                studio_id: studioId,
                user_id: localStorage.getItem("userId"),
                created_by: localStorage.getItem("userName")
            };

            try {
                ChatModuleAPI.submitFeedback(request).then((response) => {
                    // setFeedbackPageType(false);
                    if (response.data.status_code === "200") {
                        setFeedbackResponseType("Thank you for submitting your feedback");
                        setFeedbackDesc("Your feedback is valuable for us");
                        props.setFeedbackMessage("Thank you for submitting your feedback");
                    } else {
                        setFeedbackResponseType("ERROR");
                        setFeedbackDesc("Something went wrong. Please try again later");
                    }
                });
            } catch (error) {
                console.error("Error sending message:", error);
            }
        } else {
            document.getElementById("comments-err").style.display = "contents";
            document.getElementById("commentsTextArea").classList.add("err");

        }
    }
    const onChangeInput = (val) => {
        if (val != undefined && val != "") {
            setCommentMandatory(false);
        }
        setCommentsVal(val)
    }
    const hideErrMessage = () => {
        document.getElementById("comments-err").style.display = "none";
        document.getElementById("commentsTextArea").classList.remove("err");
    }

    return (
        <div className="feedback-body">
            {isFeedbackPage ? (
                <>
                    <div className="feedback-header">
                        <h6>Customer Feedback</h6>
                    </div>
                    <div className="feedback-details">
                        <div className="row">
                            <label>How was your experience?</label>
                            <ul className="star-rating">
                                {ratingVal.map((res) => (
                                    <li className="star-list">
                                        <div className="" id={res} onClick={(e) => onClickStarRating(e, res)}>
                                            <SVGExporter
                                                type="star" />
                                        </div>
                                        <span>{res}</span>
                                    </li>
                                ))}
                            </ul>
                        </div >
                        <div className="row">
                            <label for="name">How likely would you recommend us to your friends/relatives?
                                <span class="important"></span>
                                <span class="feedback-form-error"> </span>
                            </label>
                            <div class="form-inline npsExperienceType">
                                <div class="nps-num-wrap">
                                    <div class="num-exp-wrap square-shape-nums">
                                        {recommendVal.map((data) => (
                                            <div
                                                class="num-shapes"
                                                id={data.val}
                                                onClick={(e) => onClickRecommend(e, data)}
                                            >
                                                {data.val}
                                            </div>
                                        ))}
                                    </div>
                                    <div class="exp-indicator">
                                        <div class="txt-indicator">Not at all likely</div>
                                        <div class="txt-indicator">Very Likely</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row comments-row">
                            <h6 className="comments-header">Comments <span className="inline-error" id="comments-err">Please enter comments</span></h6>
                            <label>Help us improve your feedback is very valuable</label>
                            <textarea
                                id="commentsTextArea"
                                placeholder="Leave your comments here"
                                onChange={(e) => onChangeInput(e.target.value)}
                                onFocus={() => hideErrMessage()}
                            >

                            </textarea>
                        </div>
                        <div className="feedback-buttons">
                            <button className="btn-secondary">Cancel</button>
                            <button
                                className="btn-primary"
                                onClick={() => onSubmitFeedback()}
                            >Submit</button>
                        </div>
                    </div >
                </>
            ) : (
                <div className="chat-intro feedback-chat-msg">
                    <img src={feedabckSuccLogo} alt="Chatbot" className="chatbot-image" />
                    <h2>{feedbackResponseType}</h2>
                    <p>{feedbackDesc}</p>
                </div>
            )}
        </div >
    )
}

export default FeedBack;