import { CognitoUserPool } from "amazon-cognito-identity-js";

// Creating cognito constructor
const poolData = {
  UserPoolId: "ap-south-1_g5O5G5hYS",
  ClientId: "4mgmeuhst71evcglj6d0hc6of6",
};

const userPool = new CognitoUserPool(poolData);

export default userPool;
