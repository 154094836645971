import axios from "axios";
import { backendURL, adminBackendURL, externalBackendURL } from "../configurations/config";
const module = "api/studio/";

const ChatModuleAPI = {
  getStudio: async (studioId) => {
    try {
      const response = await axios.get(
        `${backendURL}${module}get_studio/${studioId}`
      );
      return response;
    } catch (error) {
      console.error("Error in getStudio:", error);
      throw error;
    }
  },

  getTopUsedQueries: async (studioId) => {
    try {
      const response = await axios.get(
        `${adminBackendURL}api/dashboard/top_queries/${studioId}`
      );
      return response;
    } catch (error) {
      console.error("Error in getStudio:", error);
      throw error;
    }
  },

  getSessionList: async (studioId) => {
    try {
      const requestJson = {
        studioId: studioId,
      };
      const response = await axios.post(
        `${backendURL}api/get_studio_chat_session`,
        requestJson
      );

      return response;
    } catch (error) {
      console.error("Error in getSessionList:", error);
      throw error;
    }
  },
  getChatSessionData: async (sessionId) => {
    try {
      const response = await axios.get(
        `${backendURL}api/get_chat_session?sessionId=${sessionId}`
      );

      return response;
    } catch (error) {
      console.error("Error in getSessionList:", error);
      throw error;
    }
  },

  chatAPI: async (request) => {
    try {
      const response = await axios.post(
        `${externalBackendURL}`,
        request
      );

      return response;
    } catch (error) {
      console.error("Error in chatAPI:", error);
      throw error;
    }
  },

  agentAPI: async (request) => {
    try {
      const response = await axios.post(
        `${externalBackendURL}`,
        request
      );

      return response;
    } catch (error) {
      console.error("Error in chatAPI:", error);
      throw error;
    }
  },
  voiceChatAPI: async (request) => {
    try {
      const response = await axios.post(
        `${backendURL}api/voice/chatapi`,
        request
      );
      return response;
    } catch (error) {
      console.error("Error in listStudio:", error);
      throw error;
    }
  },
  textToSpeech: async (text, languageCode) => {
    try {
      const data = { text: text, languageCode: languageCode };
      const response = await axios.post(`${backendURL}api/voice/polly`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });
      return response;
    } catch (error) {
      console.error("Error in textToSpeech:", error);
      throw error;
    }
  },

  submitFeedback: async (request) => {
    try {
      const response = await axios.post(
        `${adminBackendURL}api/feedback/create_feedback`,
        request
      );

      return response;
    } catch (error) {
      console.error("Error in getSessionList:", error);
      throw error;
    }
  },
  onSaveThumbsRating: async (request, chatId) => {
    try {
      const response = await axios.put(
        `${adminBackendURL}api/chat/update_chathistory/${chatId}`,
        request
      );

      return response;
    } catch (error) {
      console.error("Error in getSessionList:", error);
      throw error;
    }
  },
};

export { ChatModuleAPI };
