import React from 'react'

const SVGExporter = ({ type }) => {
    return (
        <React.Fragment>
            {(() => {
                switch (type) {
                    case 'star':
                        return (
                            <svg
                                width="53"
                                height="51"
                                viewBox="0 0 53 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.14077 20.8884C7.78931 20.9235 7.49057 21.1518 7.38513 21.4854C7.27969 21.819 7.38513 
                                22.1878 7.64873 22.4161L17.103 30.7218L14.3441 42.996C14.2738 43.3297 14.3968 43.6984 
                                14.6955 43.8916C14.9767 44.1023 15.3633 44.1198 15.6621 43.9442L26.5046 37.5174L37.3472 
                                43.9267C37.6459 44.1023 38.0326 44.0847 38.3137 43.874C38.5949 43.6633 38.7355 43.3121 
                                38.6476 42.9609L35.8711 30.6867L45.3254 22.3634C45.589 22.1351 45.6944 21.7664 45.5714 
                                21.4327C45.466 21.0991 45.1672 20.8708 44.8158 20.8357L32.2686 19.6592L27.2778 8.10492C27.1373 
                                7.78885 26.8209 7.57812 26.4695 7.57812C26.118 7.57812 25.8017 7.78885 25.6611 8.10492L20.6704 
                                19.6592L8.14077 20.8357V20.8884Z"
                                    fill="#C8C8C8"
                                />
                                <path
                                    d="M51.1204 21.6373C50.5053 22.0588 49.9255 22.5153 49.328 22.9367L47.5531 24.2713C46.9732 
                                24.7103 46.3933 25.1844 45.8134 25.6409C44.636 26.554 43.5288 27.5022 42.3866 28.4329C41.8243 
                                28.907 41.2619 29.3987 40.6996 29.8728L39.0126 31.3127L38.5732 31.699L38.7138 32.2785C39.0477 
                                33.7184 39.3816 35.1583 39.7331 36.5806C40.1197 38.0029 40.4536 39.4428 40.8753 40.8652C41.0862 
                                41.5676 41.2619 42.2875 41.4904 42.9899L42.1406 45.1146C42.5975 46.5194 43.0544 47.9242 43.5991 
                                49.329C43.4937 47.8364 43.3004 46.3789 43.1071 44.9039L42.7732 42.7089C42.6678 41.9714 42.5272 
                                41.2515 42.4042 40.5315C42.1757 39.0741 41.8594 37.6342 41.5958 36.1767C41.3498 34.93 41.0862 
                                33.6657 40.805 32.419L42.0176 31.3303C42.5623 30.8386 43.1247 30.3469 43.6519 29.8377C44.7238 
                                28.8192 45.8133 27.8359 46.8677 26.7823C47.3949 26.2555 47.9221 25.7638 48.4493 25.2195L49.9957 
                                23.6391C50.5053 23.0948 51.015 22.568 51.5246 22.0236C52.0342 21.4793 52.5263 20.9174 53.0007 
                                20.3555C52.3681 20.7593 51.7706 21.1807 51.1556 21.6197L51.1204 21.6373Z"
                                    fill="#C8C8C8"
                                />
                                <path
                                    d="M28.9098 4.39983L29.6303 6.48944C29.8763 7.19183 30.1399 7.87664 30.4035 8.56147C30.8955 
                                9.94869 31.4754 11.3184 32.0026 12.688C32.2662 13.3728 32.565 14.0576 32.8461 14.7249L33.7072 
                                16.7618L33.9357 17.3062L34.5331 17.3589C36.0093 17.4993 37.4679 17.6223 38.944 17.7101C40.4201 
                                17.7979 41.8787 17.9032 43.3548 17.9559C44.0929 17.9735 44.831 18.0261 45.569 18.0437L47.7832 
                                18.0788C49.2594 18.0788 50.7355 18.0788 52.2292 18.0086C50.7707 17.6574 49.3297 17.3764 47.8711 
                                17.113L45.6745 16.7443C44.954 16.6214 44.2159 16.5336 43.4778 16.4107C42.0193 16.1824 40.5431 
                                16.0243 39.0846 15.8312C37.8193 15.6731 36.5365 15.5502 35.2712 15.4273L34.6034 13.9523C34.3047 
                                13.285 34.0059 12.6002 33.6896 11.9329C33.057 10.5984 32.442 9.26385 31.7742 7.94687C31.4403 
                                7.2796 31.124 6.61235 30.7725 5.96264L29.7357 3.99594C29.3842 3.34623 29.0328 2.69652 28.6637 
                                2.04681C28.2947 1.3971 27.9257 0.764961 27.5391 0.132812C27.7324 0.85276 27.9432 1.57271 
                                28.1717 2.2751C28.3826 2.97748 28.6286 3.67987 28.857 4.38226L28.9098 4.39983Z"
                                    fill="#C8C8C8"
                                />
                                <path
                                    d="M5.15496 18.112L7.36917 18.0769C8.10724 18.0769 8.8453 18.0242 9.58337 17.9891C11.0595 
                                17.9364 12.5356 17.831 14.0118 17.7432C14.7499 17.7081 15.4879 17.6203 16.2084 17.5676L18.4226 
                                17.392L19.0025 17.3394L19.231 16.795C19.8109 15.4429 20.3908 14.0733 20.9356 12.7036C21.4628 
                                11.3339 22.0427 9.96428 22.5347 8.57707C22.7807 7.87468 23.0619 7.18987 23.2904 6.48749L24.0109 
                                4.39788C24.4678 2.9931 24.9422 1.58833 25.3113 0.148438C24.5205 1.41274 23.8176 2.71215 23.1146 
                                4.01156L22.0954 5.97824C21.7615 6.62795 21.4276 7.29523 21.1113 7.96249C20.4435 9.27947 19.846 
                                10.6316 19.2134 11.9661C18.6862 13.125 18.159 14.3015 17.6494 15.478L16.0327 15.6536C15.2946 
                                15.7414 14.5741 15.7941 13.8361 15.8995C12.3775 16.0926 10.9014 16.2507 9.44279 16.4789C8.7223 
                                16.5843 7.98423 16.6896 7.24616 16.8126L5.06709 17.1813C4.3466 17.3218 3.60853 17.4447 2.88804 
                                17.6027C2.16754 17.7432 1.42948 17.9013 0.708984 18.0769C1.44705 18.112 2.20269 18.1296 
                                2.94076 18.1471C3.67882 18.1471 4.4169 18.1471 5.15496 18.1471V18.112Z"
                                    fill="#C8C8C8"
                                />
                                <path
                                    d="M12.1108 40.8789C12.5149 39.4565 12.8664 38.0342 13.2354 36.5943C13.4287 35.8744 
                                13.5693 35.1544 13.7451 34.4345L14.2547 32.2746L14.3953 31.6952L13.9559 31.3088C12.8488 
                                30.3431 11.7242 29.3773 10.5995 28.4291C9.45723 27.4984 8.33256 26.5326 7.15516 
                                25.6371C6.57525 25.1805 5.99535 24.724 5.41543 24.2674L3.64055 22.9329C2.44558 22.0549 
                                1.25062 21.1769 0.00292969 20.3867C0.969448 21.5281 1.98868 22.5993 3.00792 23.6704L4.55436 
                                25.2508C5.06398 25.7776 5.60873 26.2868 6.13592 26.796C7.19031 27.832 8.27984 28.8329 9.3518 
                                29.8338C10.2832 30.6943 11.2497 31.5547 12.1986 32.3976L11.8647 33.9955C11.7242 34.7154 11.5484 
                                35.4354 11.4078 36.1553C11.1442 37.6128 10.8279 39.0527 10.5995 40.5101C10.4765 41.2476 10.3359 
                                41.9676 10.2304 42.6875L9.91413 44.8825C9.82627 45.62 9.72084 46.3575 9.63297 47.095C9.54511 
                                47.8325 9.47481 48.57 9.42209 49.3075C9.68569 48.6051 9.93171 47.9028 10.1777 47.2004C10.4238 
                                46.498 10.6346 45.7956 10.8631 45.0932L11.5133 42.9685C11.7242 42.2661 11.9175 41.5462 12.1108 
                                40.8438V40.8789Z"
                                    fill="#C8C8C8"
                                />
                                <path
                                    d="M38.1719 47.9863L36.3443 46.722C35.7468 46.3005 35.1142 45.8967 34.4991 45.4928C33.269 
                                44.6675 32.0214 43.8949 30.7737 43.0871C30.141 42.6833 29.5084 42.3145 28.8758 
                                41.9282L26.9779 40.7868L26.4683 40.4883L25.9587 40.7868C24.6934 41.5419 23.4281 42.2969 
                                22.1804 43.0871C20.9328 43.8949 19.6851 44.6499 18.4549 45.4928C17.8399 45.8967 
                                17.2248 46.3005 16.6098 46.722L14.7822 47.9863C13.5872 48.8643 12.3747 49.7247 11.2324 
                                50.6729C12.6207 50.111 13.9562 49.4788 15.2918 48.8291L17.2776 47.8458C17.9453 47.5122 
                                18.5955 47.161 19.2457 46.8273C20.5637 46.1601 21.8465 45.4226 23.147 44.7202C24.2541 
                                44.088 25.3612 43.4559 26.4683 42.8062L27.8741 43.6139C28.5243 43.9827 29.1394 44.3514 
                                29.7896 44.7202C31.09 45.4226 32.3728 46.1601 33.6908 46.8273C34.341 47.161 34.9912 47.5122 
                                35.659 47.8458L37.6447 48.8291C38.3125 49.1452 38.9803 49.4789 39.6656 49.7774C40.3334 50.0759 
                                41.0187 50.392 41.7041 50.6729C41.1242 50.1988 40.5267 49.7598 39.9468 49.3033C39.3493 48.8643 
                                38.7518 48.4253 38.1543 47.9863H38.1719Z"
                                    fill="#C8C8C8"
                                />
                            </svg>

                        );

                    default:
                        return null
                }
            })()}
        </React.Fragment>
    )
}

export default SVGExporter
