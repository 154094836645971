import "./Login.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import background from "../../assets/Arrow.png";
import {
  signIn,
  getUserDetails,
  resetUserPassword,
  generateotp,
  update_password,
} from "../../API/loginCognito";
import { toastNotify } from "../../utilities/toastServices";
import { loader } from "../../utilities/loader";

const Login = () => {
  const [emailId, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("studioId");
    if (id) {
      localStorage.setItem("studioId", id);
    }
  }, []);

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
  };

  const handleVisibilityToggle = () => {
    console.log("Password visibility toggled");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    loader("start");
    const id = localStorage.getItem("studioId");

    try {
      await signIn(
        emailId,
        password,
        async (session) => {
          await getUser(emailId);

          navigate(`/chat?studioId=${id}`);
          loader("stop");
          toastNotify("Login Success ", "success");
        },
        (err) => {
          loader("stop");
          toastNotify("Something went wrong", "error");
          console.error("Authentication failed", err);
        },
        () => {
          loader("stop");
        }
      );
    } catch (error) {
      loader("stop");
      toastNotify("Something went wrong", "error");
      console.error("Authentication failed", error);
    }
  };

  const getUser = async (emailId) => {
    try {
      getUserDetails(emailId).then((response) => {
        if (response.data.status_code == 200) {
          const data = response.data.data;
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("emailId", data.emailId);
          localStorage.setItem("role", data.role.role);
          localStorage.setItem("domain_name", data.domain_name);
        }
      });
    } catch (error) {
      loader("stop");
      toastNotify("Something went wrong", "error");
      console.error("Authentication failed", error);
    }
  };

  const handleGuest = () => {
    localStorage.setItem("emailId", "guest@shellkode.com");
    localStorage.setItem("userId", "1");
    const id = localStorage.getItem("studioId");
    if (id) {
      navigate(`/chat?studioId=${id}`);
    } else {
      console.error("studioId is missing");
    }
  };

  // OnChange method for input data
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "emailId") {
      setEmail(value.trim());
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src={logo} alt="Logo" className="logo" />
        <h2>Log In</h2>
        <div className="input-container">
          <label htmlFor="email">Email Id</label>
          <input
            type="email"
            className="login-input"
            placeholder="Enter your Email id"
            onChange={handleChange}
            autoComplete="off"
            name="emailId"
            value={emailId}
          />
        </div>
        <div className="input-container">
          <label htmlFor="password">Password</label>
          <div className="password-input-container">
            <input
              type="password"
              name="password"
              className="login-input"
              placeholder="Enter Password"
              value={password}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="remember-me">
          <div className="forgot-password">
            <a href="#" onClick={handleForgotPassword}>
              Forgot password?
            </a>
          </div>
        </div>
        <button
          type="submit"
          className="submit-button"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Sign in
        </button>
        <div className="alt-login">
          <p>OR</p>
          <button type="button" className="guest-button" onClick={handleGuest}>
            Continue as guest
          </button>
        </div>
      </div>
      <img src={background} alt="Background" className="background-image" />
    </div>
  );
};

export default Login;
