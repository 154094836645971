import React from "react";
import logo from "../../assets/logo.svg";
import { signOut } from "../../API/loginCognito";
import { useNavigate } from "react-router-dom";

function ChatHeader() {
  const navigate = useNavigate();

  // ? Method to Signout
  const logout = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("studioId");
    signOut();
    navigate(`/login?studioId=${id}`);
  };
  return (
    <div className="chat-header">
      <img src={logo} alt="Shellkode Logo" className="header-logo" />
      <button className="logout-button" onClick={logout}>
        Log out
      </button>
    </div>
  );
}

export default ChatHeader;
