import React, { useState, useLayoutEffect } from "react";
// import "./ChatSession.css";
import { ChatModuleAPI } from "../../API/chatAPI";
import ChatHeader from "./chatHeader";
import Sidebar from "./sidebar";
import ChatWindow from "./chatWindow";

function ChatSession() {
  const [welcomeMessage, setWelcomeMessage] = useState(
    "welcome to shellkode-ai"
  );
  const [botDetails, setBotDetails] = useState("");
  const [activeSession, setActiveSession] = useState("");
  const [studioId, setStudioId] = useState("");
  const [sessionList, setSessionList] = useState([]);
  const [emailId, setEmailId] = useState("");

  useLayoutEffect(() => {
    const id = localStorage.getItem("studioId");
    const emailId = localStorage.getItem("emailId");
    if (emailId === "guest@shellkode.com") {
      var chatWindow = document.querySelectorAll(".chat-window");
      chatWindow.forEach(function (chatWindow) {
        chatWindow.style.width = "100%";
        chatWindow.style.marginLeft = "0";
      });
    }

    setEmailId(emailId);
    setStudioId(id);
    getStudioDetails(id);
    getChatSession(id);

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("Media Devices supported");
    } else {
      console.log("Media Devices unsupported");
    }
  }, []);

  const getStudioDetails = async (studioId) => {
    try {
      const response = await ChatModuleAPI.getStudio(studioId);
      setBotDetails(response.data.data);
      setWelcomeMessage(response.data.data.welcome_msg);
    } catch (error) {
      console.error("Error fetching studio details:", error);
    }
  };

  const getChatSession = async (studioId) => {
    try {
      const response = await ChatModuleAPI.getSessionList(studioId);
      setSessionList(response.data.data);
    } catch (error) {
      console.error("Error fetching studio details:", error);
    }
  };

  const handleSessionClick = (sessionName) => {
    setActiveSession(sessionName);
  };

  const newSession = () => {
    setActiveSession("");
  };

  return (
    <div className="chat-session">
      <ChatHeader />
      <div className="main-content">
        {localStorage.getItem("emailId") !== "guest@shellkode.com" ? (
          <Sidebar
            handleSessionClick={handleSessionClick}
            activeSession={activeSession}
            newSession={newSession}
            sessionList={sessionList}
          />
        ) : null}

        <ChatWindow
          botDetails={botDetails}
          welcomeMessage={welcomeMessage}
          activeSession={activeSession}
          studioId={studioId}
          setActiveSession={setActiveSession}
          getChatSession={getChatSession}
        />
      </div>
    </div>
  );
}

export default ChatSession;
