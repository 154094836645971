import React, { useState, useLayoutEffect } from "react";
import { ChatModuleAPI } from "../../API/chatAPI";
import searchIcon from "../../assets/search.png";

const Sidebar = ({
  activeSession,
  handleSessionClick,
  newSession,
  sessionList,
}) => {
  return (
    <div className="sidebar">
      <div className="sidebar-heading">Chat Sessions</div>
      {/* <div className="search-wrapper">
        <input
          type="text"
          placeholder="Search for session..."
          className="search-input"
        />
        <img src={searchIcon} alt="Search" className="search-icon" />
      </div> */}
      <div className="session-list">
        {sessionList?.map((session, index) => (
          <div
            key={session}
            className={`session ${
              activeSession === session.session_id ? "active" : ""
            }`}
            onClick={() => handleSessionClick(session.session_id)}
          >
            {`Chat Session ${index + 1}`}
          </div>
        ))}
      </div>
      <button
        className="add-session-button"
        onClick={() => {
          newSession();
        }}
      >
        + New Session
      </button>
    </div>
  );
};

export default Sidebar;
