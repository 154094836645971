import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import closeChat from "../../assets/closeChat.svg";
import { ChatModuleAPI } from "../../API/chatAPI";
import sendIcon from "../../assets/sendIcon-sm.svg";
import MicIcon from "../../assets/icon_mic.svg";
import chatbot from "../../assets/chatbot.png";
import userAvatar from "../../assets/user.png";
import playIcon from "../../assets/play.png";
import copyIcon from "../../assets/copy.svg";
import thumbsUp from "../../assets/thumbsUp.svg";
import thumbsDown from "../../assets/thumbsDown.svg";
import thumbsDownFill from "../../assets/thumbsDownFill.svg";
import thumbsUpFill from "../../assets/thumbsUpFill.svg";
import shellkodeIcon from "../../assets/shellkode.sm-Icon.svg";
import FeedBack from "./feedback.jsx";
import widgetIcon from "../../assets/feedback_chat_logo.svg";
import stopMic from "../../assets/stopMic.svg";

import pulse from "../../assets/pulse.gif";
import pause from "../../assets/pulsePause.svg";
import "./chatbot.css";
function Chatbot() {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showIntroAndFAQ, setShowIntroAndFAQ] = useState(true);
  const [welcomeMessage, setwelcomeMessage] = useState("");
  const [hideSpeaker, setHideSpeaker] = useState(false);
  const [botDetails, setBotDetails] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [sttEnabled, setSttEnabled] = useState(false);
  const [audio, setAudio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [studioId, setStudioId] = useState("");
  const [messages, setMessages] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [chatId, setChatId] = useState("");
  const [ttsEnabled, setTtsEnabled] = useState(false);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [possibleValues, setPossibleValues] = useState([]);

  const chatEndRef = useRef(null);

  // ? Check for media devices support
  useLayoutEffect(() => {
    var uuid = uuidv4();
    setSessionId(uuid);
    const urlParams = new URLSearchParams(window.location.search);
    const studioId = urlParams.get("studioId");
    setStudioId(studioId);
    getStudioDetails(studioId);
    getTopUsedQueries(studioId);

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("Media Devices supported");
    } else {
      console.log("Media Devices unsupported");
    }
  }, []);

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, loading]);

  const getStudioDetails = (studioId) => {
    ChatModuleAPI.getStudio(studioId).then((response) => {
      setBotDetails(response.data.data);
      setwelcomeMessage(response.data.data.welcome_msg);
      setSttEnabled(response.data.data.is_speech_to_text);
      setTtsEnabled(response.data.data.is_text_to_speech);
    });
  };

  const getTopUsedQueries = (studioId) => {
    ChatModuleAPI.getTopUsedQueries(studioId).then((response) => {
      setPossibleValues(response.data.data.top_queries);
    });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const closeBot = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.length > 0) {
      const filteredSuggestions = possibleValues.filter((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage(event.target.value, "en");
    }
  };
  const sendRequest = () => {
    if (inputValue.trim()) {
      handleSendMessage(inputValue, "en");
    }
  };
  const handleSendMessage = (query, languageCode) => {
    setInputValue("");
    setSuggestions([]);

    setShowIntroAndFAQ(false);
    setLoading(true);
    let userRequest = {
      type: "user",
      body: query,
      isFeedbackRowVisible: false
    };
    // ? Append chat to session logsp
    setMessages((prevsessionConversation) => [
      ...prevsessionConversation,
      userRequest,
    ]);

    let request = {
      // query: query,
      // sessionId: sessionId,
      // knowledgeBaseId: ["PI8ABSBENB", "NHTGZF67I9"],
      // account_id: botDetails.accountId,
      // channel_type: 1,
      // studio_id: studioId,
      // user_id: "1",
      // agentId: "WEWI0SHZIY",
      // agentAliasId: "TSTALIASID",
      user_input: query,
      session_id: sessionId
    };

    try {
      ChatModuleAPI.agentAPI(request).then((response) => {
        // setSessionId(response.data.data.sessionId);
        // setChatId(response.data.data.chat_id);
	response.data.chatbot_response = response.data.chatbot_response.replace(/<br\s*[\/]?>/gi, '\n');
        let userRequest = {
          type: "system",
          body: response.data.chatbot_response,
          // sourceDoc: response.data.data.sourceDoc,
          // presigned_url: response.data.data.presigned_url,
          isFeedbackRowVisible: false
        };
        // if (ttsEnabled) {
        //   playText(response.data.data.output, languageCode, userRequest);
        // } else {
        setMessages((prevSessionConversation) => [
          ...prevSessionConversation,
          userRequest,
        ]);
        setLoading(false);
        // }
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const setFeedbackMessage = (msg) => {
    setFeedbackOpen(false);
    let obj = {
      type: "system",
      body: msg,
      isFeedbackRowVisible: false
    }
    setMessages((prevSessionConversation) => [
      ...prevSessionConversation,
      obj,
    ]);
  }
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const onClickFeedback = () => {
    setIsOpen(!isOpen);
    setFeedbackOpen(true);
  };

  const onClickThumbsBtn = (e, id, type, nextId, nextImgType) => {
    try {
      let request = {
        like_unlike: id,
      };
      console.log(request, chatId)
      ChatModuleAPI.onSaveThumbsRating(request, chatId).then((response) => {
        if (response.data.status_code === "200") {
          document.getElementById(e.target.id).src = type;
          document.getElementById(nextId).src = nextImgType;
        }
      });
    } catch (error) {
      console.error("Error on saving thumbs rating:", error);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      let chunks = [];
      setMediaRecorder(recorder);
      recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };
      recorder.onstop = async () => {
        setLoading(true);
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        chunks = [];
        const formData = new FormData();
        formData.append("file", audioBlob, "recording.wav");
        ChatModuleAPI.voiceChatAPI(formData).then((response) => {
          if (response.status === 200) {
            handleSendMessage(
              response.data.data.transcription,
              response.data.data.language
            );
          }
        });
      };
      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };
  const handleAudioEnded = () => {
    setHideSpeaker(true);
  };

  const stopVoice = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      setHideSpeaker(true);
    }
  };

  const repeatText = (text) => {
    if (audio) {
      audio.play();
      setHideSpeaker(false);
    }
  };

  const playText = (text, code, userRequest) => {
    setHideSpeaker(false);

    ChatModuleAPI.textToSpeech(text, code).then((response) => {
      if (response.status === 200) {
        const blob = response.data;
        const audioObj = new Audio(URL.createObjectURL(blob));
        audioObj.addEventListener("ended", handleAudioEnded);
        setAudio(audioObj);
        audioObj
          .play()
          .then(() => {
            if (userRequest !== "") {
              setMessages((prevSessionConversation) => [
                ...prevSessionConversation,
                userRequest,
              ]);
              setLoading(false);
            }
          })
          .catch((error) => console.error("Playback failed", error));
      }
    });
  };
  const handleSuggestionClick = (value) => {
    setInputValue(value);

    setSuggestions([]);
  };
  return (
    <>
      <div className="bot-header-container">
        <div className="centre-header-container">
          <img src="https://dam.freshworks.com/m/201271b18ba1bfc1/original/headerLogoDark.webp"></img>
        </div>
        <div className="right-header-container">
          <img src={closeChat} onClick={closeBot}></img>
          {isOpen ? (
            <ul className="settings-dropdown" ref={dropdownRef}>
              <li className="profile-dispname" onClick={handleRefresh}>
                <span>New Session</span>
              </li>
              <li className="profile-dispname" onClick={onClickFeedback}>
                <span>Feedback</span>
              </li>
              <li className="profile-dispname">
                <span>Close</span>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
      {!isFeedbackOpen ? (
        <>
          <div className="bot-main-container">
            {showIntroAndFAQ && (
              <div className="chat-intro">
                <img src={widgetIcon}></img>
                <h2 className="welcome-msg-header">
                  Welcome to the Shellkode-AI
                </h2>
                <p className="welcome-msg">{welcomeMessage}</p>
              </div>
            )}
            <div className="chat-page" id="chat-page">
              <div className="chat-page-row">
                {messages.map((message, index, array) => (
                  <div key={index} className={`chat-message ${message.type}`}>
                    {message.type === "system" ? (
                      <div className="chat-left">
                        <img
                          src="https://fc-use1-00-pics-bkt-00.s3.amazonaws.com/0d74c4ce13ab60bbc1107299bb1daf5bd15e086ab904f0e5a55d5f95a8dbb7c9/f_appLevelPicFull/img_c1mok6oo63_d20a1c2c58fdc41bd7473bd820ab1835d6984a20e0c8419a64c8a13beed636b7.png"
                          alt="Bot Avatar"
                          className="chat-avatar"
                        />
                        <div>
                          <div className="chat-text preformatted-text">
                            {message.body}
                            {/* <div className="message-actions">
                              {message.hasOwnProperty("sourceDoc") &&
                                message.sourceDoc !== "" ? (
                                <h6 className="sourceTitle">
                                  Source Document:{" "}
                                  <a
                                    href={message.presigned_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="source-link textDecoration-line"
                                  >
                                    {message.sourceDoc}
                                  </a>
                                </h6>
                              ) : null}
                            </div> */}
                          </div>
                          {message.isFeedbackRowVisible ? (
                            <div className="bot-msg-settings">
                              <div className="d-flex gap-10 align-center">
                                <p className="feedback-txt">
                                  Please provide feedback:
                                </p>
                                <div className=" d-flex gap-5 thumbs-img">
                                  <button>
                                    <img
                                      id={index + "_15"}
                                      onClick={(e) =>
                                        onClickThumbsBtn(
                                          e,
                                          "15",
                                          thumbsUpFill,
                                          index + "_16",
                                          thumbsDown
                                        )
                                      }
                                      src={thumbsUp}
                                    />
                                  </button>
                                  <button>
                                    <img
                                      id={index + "_16"}
                                      onClick={(e) =>
                                        onClickThumbsBtn(
                                          e,
                                          "16",
                                          thumbsDownFill,
                                          index + "_15",
                                          thumbsUp
                                        )
                                      }
                                      src={thumbsDown}
                                    />
                                  </button>
                                </div>
                              </div>

                              {ttsEnabled ? (
                                <>
                                  {index === array.length - 1 &&
                                    array.length > 1 &&
                                    (hideSpeaker ? (
                                      <img
                                        src={playIcon}
                                        onClick={() => {
                                          repeatText(message.body);
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={stopMic}
                                        onClick={() => {
                                          stopVoice();
                                        }}
                                      />
                                    ))}
                                </>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div className="chat-right">
                        <p className="chat-text">{message.body}</p>
                        <img
                          src={userAvatar}
                          alt="User Avatar"
                          className="chat-avatar"
                        />
                      </div>
                    )}
                  </div>
                ))}
                {loading && (
                  <div className="chat-message bot align-center img-loader">
                    <img
                      src="https://fc-use1-00-pics-bkt-00.s3.amazonaws.com/0d74c4ce13ab60bbc1107299bb1daf5bd15e086ab904f0e5a55d5f95a8dbb7c9/f_appLevelPicFull/img_c1mok6oo63_d20a1c2c58fdc41bd7473bd820ab1835d6984a20e0c8419a64c8a13beed636b7.png"
                      alt="Bot Avatar"
                      className="chat-avatar"
                    />
                    <div className="">
                      <div className="dots-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={chatEndRef} />
              </div>
            </div>
          </div>

          <div className="chat-footer">
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}

                  // Additional props
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            <div className="chat-input d-flex justify-center">
              {isRecording ? (
                <>
                  <img
                    className="pulse"
                    onClick={() => {
                      isRecording ? stopRecording() : startRecording();
                    }}
                    src={pulse}
                  ></img>
                  <img
                    className="pulse-pause"
                    onClick={() => {
                      isRecording ? stopRecording() : startRecording();
                    }}
                    src={pause}
                  ></img>
                </>
              ) : (
                <>
                  <input
                    className="chat-input-box"
                    type="text"
                    placeholder="Ask your question"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    // onChange={(e) => handleInputChange(e)}
                    onKeyPress={handleKeyPress}
                  />
                  <img
                    className="icon-send"
                    src={sendIcon}
                    alt="sendIcon"
                    onClick={sendRequest}
                  />
                  {sttEnabled && (
                    <img
                      src={MicIcon}
                      alt="Mic"
                      className="icon-mic"
                      onClick={() => {
                        isRecording ? stopRecording() : startRecording();
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <FeedBack
            setFeedbackMessage={setFeedbackMessage} />
        </>
      )}
      <div className="poweredBy">Powered by Shellkode.AI</div>
    </>
  );
}
export default Chatbot;
